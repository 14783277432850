<template>
  <div class="catalog__search">
    <div class="container">
      <Search />
    </div>
  </div>

  <div class="catalog-content">
    <div class="container">
      <div class="catalog__title title">Каталог</div>
      <div class="catalog-links">
        <router-link
          v-for="item in catalog"
          :key="item.id"
          :href="item.url"
          :to="{ name: 'catalog-category', params: { category: item.id } }"
          class="catalog-link"
        >
          <span class="catalog-link__icon">
            <img :src="item.img"  alt=""/>
          </span>
          <span class="catalog-link__text">{{ item.name }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/button/search";

import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("CATALOG_FETCH_DATA", {
        category: 0,
      })
      .then(() => {
        next();
      });
  },

  components: {
    Search,
  },

  computed: {
    catalog() {
      return this.$store.state.catalog.catalog;
    },
  },
};
</script>
